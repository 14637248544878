@import "../assets/styles/variables.scss";

.post-popover-button {
  color: #ffb2a6;
  font-weight: bold;
}

.profile-picture {
  width: 55px;
  background-color: #e5e5e5;
  border-radius: 50%;
  text-align: center;
  float: left;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.create-new-post--button {
  width: 80%;
  height: 47px !important;
  background-color: #0c7d89 !important;
  color: #fff !important;
  margin-top: 14px !important;
  border-radius: 30px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  margin-bottom: 24px !important;
  padding:30px 0px 30px 0px;
}

.forumHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0 9.4px;
  padding: 12px 11px 12px 11px;
  background-color: #fff;
  margin-top: 10px;
}

.gravidforum-title {
  text-align: center;
  padding: 10px 30px 20px 30px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
}

.listHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.listContainer {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  //margin-top: 12px;
  padding: 20px 25px 10px 25px;
  font-size: 14px;
  //border-radius: 15px;
  border-bottom: 1px solid #C8C8C8;
}

.listContainer-googleads {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 12px;
  font-size: 14px;
  border-radius: 15px;
}

h5.heartUnselected {
  margin-right: auto;
  margin-left: auto;
}

.borderLine {
  position: absolute;
  border-right: 1px #e5e5e5 solid;
  height: 55px;
  margin-left: 40%;
  transform: translateX(-50%);
}

.likeSection {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  border-right: 1px #e5e5e5 solid;
}
.likeWithoutCommentSection {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.commentSection {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
}

.post-date {
  font-size: 12px;
  color: #bdbdbd;
}

.post-title {
  font-size: 16px;
  margin-bottom: -5px;
  font-weight: bold;
  color: #0c7d89;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.empty-results {
  font-size: 17px;
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-bottom: 20px;
}

.first-comment-text {
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;
  white-space: pre-line;
}

.recipe-content--container {
  margin-top: 10px;
}

.post-title-forumPost {
  font-size: 14px !important;
  margin-bottom: -5px;
  margin-top: 10px;
  font-weight: bold;
  color: #0c7d89;
}
.post-title-text {
  //word-break: break-all;
}

.first-comment-text-forumPost {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
  white-space: pre-line;
}

.commentsContainer {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  align-items: normal;
}

.listItemActionsContainer {
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ListLikesandComments {
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}

.listActions {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
}

.category-tag {
  background-color: #c5204f;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
}

.post-username {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 700;
}

.post-header-section {
  float: left;
}
.avatar-container {
  display: flex;
  // align-items: center;
  position: relative;
}
.avatar {
  margin-right: 10px;
}

.forum-text-section {
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}

.replied-to-comment--container {
  background-color: #e5e5e5;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 12px;
}

.report-info {
  // right: 20px;
  //position: absolute;
  // background-color: #e5e5e5;
  border-radius: 10px;
}

.report-info-profile {
  right: 0px;
  position: absolute;
}
.reportinfo-button {
  padding-left: 15px;
  padding-right: 15px;
}

.containerTitle {
  font-size: 26px;
}

.newestContainer {
  display: flex;
  margin-right: 20px;
  font-size: 24px;
  align-items: center;
  padding-left: 20px;
}

.checkboxContainer {
  display: flex;
  width: 500px;
  justify-content: space-evenly;
  align-items: center;
}

.back {
  width: 24px;
  object-fit: contain;
}

.buttonAdd {
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0c7d89;
  cursor: pointer;
  padding: 15px;
  border: none;
}

.threadContainer {
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
  /* background-color: #333333; */
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}

.backToHomePage {
  margin-top: 10px;
  padding: 20px;
  align-items: center;
}

.closeThreadButton {
  font-size: 22px;
  margin-top: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  cursor: pointer;
}

.comments {
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonAddText {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.new-post-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.text-area-post {
  height: 45px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  outline: none !important;
  padding: 10px;
  resize: none;
}

.show-more {
  text-align: center;
  margin: 20px 0px 20px 0px;
  font-weight: bold;
  padding: 15px;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0c7d89;
  color:#ffffff;
}

.create-new-post-header-container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
}
.anonymous-profile-name {
  color: #0c7d89;
  font-style: italic;
  margin-right: 10px;
  font-size: 14px;
}

.user-name-hover {
  cursor: pointer;
}

.user-name-hover:hover {
  text-decoration: underline;
}
.profile-picture-hover:hover {
  opacity: 0.8;
}

@media #{$media-small} {
  .buttonAdd {
    width: 96px;
    height: 37px;
  }
  .buttonAddText {
    font-size: 10px;
  }
  .checkboxContainer {
    width: 750px;
  }
  .newestContainer {
    font-size: 14px;
  }
  .avatar-container {
    // margin-top: 15px;
  }
  .listContainer {
    //margin-top: 8px;
  }
  .listContainer-googleads {
    margin-top: 8px;
  }
}
@media #{$media-big} {
  .listContainer {
    font-size: 16px;
  }
}

@media #{$media-medium} {
  .listContainer {
    font-size: 16px;
  }
}

@media #{$media-small} {
  .closeThreadButton {
    font-size: 17px;
  }
  .backToHomePage {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .post-title {
    -webkit-line-clamp: 2;
  }
  .listContainer {
    padding: 15px 12px 10px 12px;
    //margin-top: 10px;
   // margin-left: 5px;
    //margin-right: 5px;
  }
  .listContainer-googleads {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .first-comment-text {
    margin-top: 6px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    //margin-left: 82px;
  }
  .category-tag {
    font-size: 8px;
  }
  .post-date {
    font-size: 8px im !important;
  }
  .profile-picture {
    height: 50px;
    width: 50px;
  }

  .show-more {
    margin-left: 15px;
    margin-right: 15px;
  }

  .gravidforum-title {
    padding: 0px 10px 4px 10px;
  }
}
