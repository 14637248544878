@import "../assets/styles/variables.scss";

body,
html {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
  max-width: 100% !important;
}

h5 {
  font-weight: normal;
}

.wrapper {
  min-height: 100%;
}

.searchContainer {
  width: 640px;
  height: 60px;
  border-radius: 10px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}
.searchInput {
  width: 100%;
  height: 50%;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  background-color: #e5e5e5;
  border: none;
  outline: none;
}
.searchButton {
  width: 150px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0c7d89;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 20px;
}

.text-field-focused {
  box-shadow: 0px 2px 0px #0c7d89 !important; // Overriding Material UI styling
  height: 50px;
  // -webkit-transition: all ease-in-out 0.15s;
  // -o-transition: all ease-in-out 0.15s;
  // transition: all ease-in-out 0.15s;
}
.search-form-without-box-shadow {
  display: flex;
  width: 100%;
  margin-right: 15px;
  // margin-left: 15px;
  background-color: $color-white !important; // Overriding Material UI styling
  justify-content: center;
  align-items: center;
  border-radius: 5px !important; // Overriding Material UI styling
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}
.search-form {
  display: flex;
  width: 100%;
  background-color: $color-white !important; // Overriding Material UI styling
  border: 1px solid #888080 !important; // Overriding Material UI styling
  box-shadow: none !important; // Overriding Material UI styling
  align-items: center;
  border-radius: 10px !important; // Overriding Material UI styling
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  height: 50px;
  margin-bottom: 13px;
}
.search-icon {
  margin-left: 15px;
  margin-top: 5px;
}

.search-field {
  font-size: 16px !important;
  margin-left: 5px;
  width: 100%;
}

.new-post-button {
  background-color: #ffffff;
  width: 100%;
  text-transform: none;
  border-radius: 10px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-left: 30px;
  font-style: italic;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
}

.header-image {
  position: relative;
  height: 400px;
  width: 100%;
}

.header-image::before {
  content: "";
  opacity: 0.9;
  background-image: url("https://babyforum.azureedge.net/images/website/babyforum-head.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.header-text {
  position: relative;
  text-align: center;
  margin-top: 50px;
  font-size: 25px;
  color: #fff;
  text-shadow: 0 0 3px #000;
}

.btn-member {
  background-color: #0c7d89 !important;
  color: #fff !important;
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
  font-size: 18px !important;
  padding: 15px 20px 15px 20px !important;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.16) !important;
  font-weight: bold !important;
  border-radius: 30px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.btn-member:hover {
  background-color: #87c7cc !important;
  color: #0c7d89 !important;
}

.new-post-button-title {
  margin-left: 55px;
  font-size: 16px;
}

.new-post-button::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #fff;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
  border-left: 0;
  border-top: 0;
  margin-top: -4px;
  margin-left: -20px;
}

.settings-input {
  background-color: $color-white;
  height: 45px !important; // Overriding Material UI styling
  padding: 12px !important; // Overriding Material UI styling
  font-size: 14px !important; // Overriding Material UI styling
  font-weight: 400 !important; // Overriding Material UI styling
  border: 1px solid $color-border;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.text-field--focused {
  // -webkit-transition: all ease-in-out 0.15s;
  // -o-transition: all ease-in-out 0.15s;
  // transition: all ease-in-out 0.15s;
  //border: 2px solid $color-main-blue !important; // Overriding Material UI styling
  //padding: 12px 11px !important; // Overriding Material UI styling
}
.settings-text-area {
  border: 2px solid transparent;
  padding: 12px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-family: Poppins;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 14px;
  border: 1px solid #e5e5e5;
  width: 100%;
}
.settings-text-area-ios {
  // padding: 12px;
  height: 30px !important;
  padding-left: 10px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-family: Poppins;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #e5e5e5;
  width: 100%;
}
.text-area--focused {
  border: 2px solid $color-main-blue !important; // Overriding Material UI styling
}
.settings-container {
  margin-top: 10px;
}

.category-select {
  //border:1px solid #e0e3e7;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 16px !important;
  border-radius: 10px;
}

.MuiTabs-root {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.pencil-icon {
  color: #fff;
  width: 19px;
  height: 18px;
  margin-right: 10px;
}

.selectOrder-selector {
  color: #000 !important;
  border: 1px solid #888080 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  margin-bottom: 5px;
  height: 40px;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  width: 220px;
  text-align: center;
}

.select-recipe-category-selector {
  color: #000 !important;
  border: 1px solid #888080 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  margin-bottom: 5px;
  height: 40px;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  width: 100%;
  text-align: center;
}

.selectOrder-selector:hover {
  border: 1px solid #0c7d89 !important;
}
.select-recipe-category-selector:hover {
  border: 1px solid #0c7d89 !important;
}

.filter--container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.selectCategories-selector {
  background-color: #fff !important;
  border: 1px solid #888080 !important;
  /*border:1px solid #80bfc4 !important; */
  border-radius: 10px !important;
  text-transform: none !important;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 220px;
}
.selectCategories-selector:hover {
  border: 1px solid #0c7d89 !important;
}

.selectCategories-selector:active {
  border: 1px solid #0c7d89 !important;
}

.add-image-actions-container {
  justify-content: center !important;
  margin-bottom: 10px;
}

.add-image-save-btn {
  color: $color-white !important;
  height: 40px;
  font-size: 15px !important;
  text-transform: none !important;
  border-radius: 10px;
  width: 190px;
}

.go-back-button-container {
  display: flex;
  align-items: center;
  width: 100px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.banner {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
}

.search-button--input-field {
  width: 110px !important;
  height: 45px !important;
  margin-right: 5px !important;
  border-radius: 30px !important;
  /* background: linear-gradient(to left top, #FFB2A6, #80BFC4) !important; */
  background-color: #0c7d89 !important;
  color: #fff !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.6 !important;
  letter-spacing: normal !important;
}

.search-button--input-field:hover {
  background-color: #0c7d89 !important;
  color: $color-white !important;
}

.search-button--input-field:active {
  background-color: #427478 !important;
  color: $color-white !important;
  border: 1px solid #fff;
}

.forum-selector-header-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.forum-selector-header-button {
  border-radius: 30px;
  width: 80%;
  height: 45px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.forum-selector-header-button-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

@media #{$media-small} {
  .filter--container {
    display: flex;
    justify-content: space-between;
  }
  .search-form {
    // height: 40px;
  }
  .MuiAvatar-root {
    margin-left: 13px;
  }
  .new-post-button {
    margin-left: 10px;
    margin-right: 10px;
  }
  .selectCategories-selector {
    font-size: 12px !important;
    width: 150px;
    // height: 40px !important;
  }
  .text-field-focused {
    // height: 40px;
  }
  .selectOrder-selector {
    text-align: center;
    font-size: 12px !important;
    text-transform: uppercase;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .select-recipe-category-selector {
    text-align: center;
    font-size: 12px !important;
    text-transform: uppercase;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .MuiPopover-paper {
    margin-top: 10px;
  }

  .new-post-button-title {
    margin-left: 20px;
    font-size: 14px;
  }
  .new-post-button {
    justify-content: left;
    margin-left: 15px;
  }
  .new-post-button::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #fff;
    box-shadow: 0 3px 0px 6 rgba(0, 0, 0, 0.16);
    border-left: 0;
    border-top: 0;
    margin-top: -4px;
    margin-left: -10px;
  }
  .search-field {
    font-size: 13px !important;
    line-height: 1.55 !important;
  }

  .header-text {
    padding: 10px 10px 0px 10px;
    font-size: 16px;
    margin-top: 10px;
  }
  .btn-member {
    display: flex !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
    font-size: 14px !important;
  }
  .header-image {
    height: 250px;
  }
}

@media #{$media-medium} {
  .selectCategories-selector {
    // width: 200px;
  }
}

@media #{$media-small} {
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
}
