body {
  margin: 0;
  font-family:
    "Roboto Serif",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  /*background: linear-gradient(to left top, #ffb2a6, #80bfc4); */
}

h1 {
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 20px;
  font-weight: 800;
}

h2 {
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 18px;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  font-family:
    "Roboto Serif",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    serif;
  font-size: 16px;
  font-weight: 500;
}

p {
  font-family:
    "Roboto Serif",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    serif;
  font-size: 16px;
  font-weight: 500;
}

section {
  font-family:
    "Roboto Serif",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

select {
  width: 364px;
  height: 57px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 20px;
  font-size: 20px;
  padding-left: 20px;
  text-align: center;
  align-items: center;
  outline: none;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
}
