@import "../assets/styles/variables.scss";

.notifications-icon--container {
  width: 45px;
  height: 45px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c7d89;
}

.notification-card-container {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $color-border;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.notifications-container {
  padding: 10px;
}

.notifications-created-date-container {
  color: #0c7d89;
  font-size: 14px;
}

.notification-unread-message {
  background-color: #3089fc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: block;
}

.notification-user-name-container {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 100px;
}

.bell {
  width: auto !important;
  height: 35px !important;
}

.bell--inactive {
  color: #fff;
}

.bell--active {
  color: #f5e506;
}

.animated {
  animation-iteration-count: 3;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes swing {
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing {
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

.notification-card-like-comment-img {
  width: 26px;
  position: absolute;
  right: 0;
  bottom: -5px;
  background-color: white;
  border-radius: 10px;
  height: 26px;
  padding: 2px;
}

@media #{$media-small} {
  .notification-card-container {
    width: 88%;
  }
}
