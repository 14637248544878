@import "../assets/styles/variables.scss";

.footer-container {
  background-color: #333;
  color: #fff;
  display: flex;
  min-height: auto;
  max-height: 100%;
  width: 100%;
  bottom: 0;
  font-weight: normal;
}

.footer-first-section {
  padding: 0px 15px 0px 15px;
}

.footer-second-section {
  margin-left: auto;
  margin-right: auto;
}

.footer-third-section {
  padding: 0px 15px 0px 15px;
}

@media #{$media-big} {
  .footer-container {
  }
}

@media #{$media-medium} {
  .footer-container {
    display: none;
  }
}

@media #{$media-full} {
  .footer-container {
    display: flex;
  }
}

@media #{$media-small} {
  .footer-container {
    display: none;
    margin-bottom: 72px;
    background-color: #0C7D89;
    color:#fff;
  }

  .footer-container h2 {
    font-size: 12px;
    width: auto;
  }
  .footer-container h3 {
    font-size: 10px;
    width: auto;
  }
}
