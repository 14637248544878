// --- Wrapper sizes
$width-min: 320px;
$width-medium: 568px;
$width-big: 1024px;
$width-full: 1260px;

// --- Media-queries
$media-small: 'only screen and (max-width: '($width-medium) ')';
$media-medium: 'only screen and (min-width: '$width-medium ')'; //and (max-width: "($width-big - 1)")
$media-big: 'only screen and (min-width: '$width-big ')'; // and (max-width: "($width-full - 1)")
$media-full: 'only screen and (min-width: '$width-full ')';
$media-only-small-and-medium: 'only screen and (max-width: '($width-big) ')';

$color-grey: #f0f2f2;
$color-white: #ffffff;
$color-main-blue: #0C7D89;
$color-border: #e0e3e7;
$color-black: #000000;