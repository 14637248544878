.header {
  height: 100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  top: 0;
  width: 100%;
}

.header_logo {
  // height: 150px;
  width: 200px;
  height: 100%;
  object-fit: contain;
  margin-left: 25px;
  //margin-top: 10px;
  cursor: pointer;
  position: relative;
  background-image: url("https://babyforum.azureedge.net/images/babyforum_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.header_icon {
  width: 40px;
  height: 40px;
  padding-right: 50px;
  object-fit: contain;
}

.header-profile-picture {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.header-link-container {
  display: flex;
  align-items: center;
}

.link_container {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  font-size: 12px;
  /* background: linear-gradient(to left top, #FFB2A6, #80BFC4); */
  background-color: #0c7d89;
  border-radius: 30px;
  padding: 15px 20px 15px 20px;
  align-items: center;
  /* margin-bottom: 10px; */
  display: flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  justify-content: center;
}

.link_container:hover {
  background-color: #427478;
  color: #fff;
}

.link_container:active {
  background-color: #427478;
  color: #fff;
  border: 1px solid #fff;
}

.header-link {
  padding: 15px 5px 15px 5px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.header_login {
  width: 20px;
  height: 20px;
  // padding-right: 8px;
  object-fit: contain;
}

@media only screen and (max-width: 479px) {
  .header {
    height: 70px;
  }
  .header_icon {
    width: 30px;
    height: 30px;
    padding-right: 40px;
    object-fit: contain;
  }
  .header_logo {
    background-image: url("https://babyforum.azureedge.net/images/babyforum-ikon.png");
    width: 50px;
    margin-left: 20px;
    height: 70%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header {
    height: 55px;
  }
  .header_icon {
    width: 30px;
    height: 30px;
    padding-right: 40px;
    object-fit: contain;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    height: 55px;
  }
  .header_icon {
    width: 30px;
    height: 30px;
    padding-right: 40px;
    object-fit: contain;
  }
}

@media only screen and (min-width: 992px) {
  .link_container {
    margin-right: 25px;
    margin-right: 50px;
    font-size: 19px;
    cursor: pointer;
  }
  .header-link {
    margin-right: 30px;
    font-size: 19px;
  }
}
