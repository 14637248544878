@import "../assets/styles/variables.scss";

.tabs--container {
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.42) !important;
  font-size: 12px;
}

.profilePic {
  width: 27px;
  height: 24px;
}

.menuTabs {
  min-width: 20px !important;
  font-size: 13px !important;
}
.menuTabIcon {
  height: 40px !important;
  width: 40px !important;
  color: black;
}

@media #{$media-big} {
  .profilePic {
    width: 48px;
    height: 100%;
  }

  .tabs--container {
    font-size: 14px;
  }

  .menuTabs {
    font-size: 16px;
  }
}
